import React from 'react'
import Header from '../../components/Header'
import FormLogin from '../../components/Form/loginForm'

function Login() {
    return (
        <>
            <div className="min-height">
                <title>"Bienvenue"</title>
                <Header titleHeader="Bienvenue" />
                <FormLogin />
            </div>
        </>
    )
  }
  
export default Login