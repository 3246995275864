import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import "../../styles.css";


function Authentication() {
    const formFields = {
      signIn: {
        username: {
          labelHidden: true,
          placeholder: 'Adresse e-mail',
          required: true,
          order: 1,
        },
        password: {
          labelHidden: true,
          placeholder: 'Mot de passe',
          required: true,
          order: 2,
        }
       },
    };

    const setCookie = (name, value, days) => {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Expire après "days" jours
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };
    
    const Redirection = async (user) => {
      const navigate = useNavigate();
      const session = await fetchAuthSession();
      if (user) {
        const token = session.tokens.accessToken.toString();
        // stocker dans les cookies
        setCookie('authToken', token, 7);
        sendRequestWithCookie();
      }
      else {
        navigate("/connexion");
      }
    };
    
    
    const sendRequestWithCookie = async () => {
      let environment = process.env.REACT_APP_ENVIRONMENT;
      try {
        if (environment === 'production') {
          window.location.href = 'https://documentation.g8.gestimag.com';
        } else {
          window.location.href = `https://documentation.${environment}.gestimag.com`;
        }
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
      }
    };

    return (
    <div className="d-flex justify-content-center mt-5">
      <Authenticator formFields={formFields} hideSignUp={true} className="authenticator">
        {/* Si déjà authentifié, redirection */}
        {({ signOut, user }) => {
          if (user) {
            return <Redirection user={user} />;
          }
        }}
      </Authenticator>
    </div>
    );
}

export default Authentication;