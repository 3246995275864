const awsConfig = {
  Auth: {
    Cognito: {
      identityPoolId: 'eu-west-1:78a84718-eaf6-4e88-98a6-f71d92bfb959', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
      userPoolId: 'eu-west-1_eqynLiP6v', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolClientId: '3h5nbcn5f3b589qcckumds1dvr', //OPTIONAL - Amazon Cognito Web Client ID
    }
  },
  Storage: {
    AWSS3: {
      bucket: 'sandbox-referencecatalog-s3-bucket',
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  },
  region: 'eu-west-1',
};
export default awsConfig;